
import { Component, Prop, Vue } from 'vue-property-decorator'
import { eventDate, isoDateToTextDate } from '@/helpers/date'
import { putEvent } from '@/services/seller.service'
@Component({
  components: {},
})
export default class EventSuperAdminFilteredTemplate extends Vue {
  @Prop() readonly event: any

  payment = [
    { id: 0, name: 'No Pago' },
    { id: 1, name: 'Pago' },
  ]

  formatPrice(value) {
    const val = (value / 1).toFixed().replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  dateFormat(isoDate) {
    return isoDateToTextDate(isoDate)
  }

  async setEventPaid(value, id) {
    const body = {
      paid: value ? 1 : 0,
    }

    const response: any = await putEvent(id, body)

    const message = value ? 'Pago' : 'No pago'

    if (response.status == 200) {
      Vue.$toast.success(`Evento actualizado como '${message}' correctamente`)
    } else {
      Vue.$toast.error(
        'Ha ocurrido un error actualizando el evento - ' + response.message
      )
    }
  }
}
