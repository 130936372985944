<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader headerText="Reporte Comisiones" :superAdmin="true" />
      </sequential-entrance>

      <div class="mt-5 p-3">
        <div>
          <v-row class="mt-0">
            <v-col cols="12" class="pt-0">
              <v-select
                item-text="name"
                item-value="id"
                v-model="filterByBusiness"
                :items="business"
                @change="getAllEvents(1, filterByPayment, filterByBusiness)"
                label="Filtra por cliente"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" class="pt-0">
              <v-select
                item-text="name"
                item-value="id"
                v-model="filterByPayment"
                :items="payment"
                @change="getAllEvents(1, filterByPayment, filterByBusiness)"
                label="Filtra por pago"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <v-row
          v-for="(event, index) in allEventsFiltered"
          class="align-center mb-5"
          :key="index"
        >
          <EventSuperAdminFilteredTemplate :event="event" />
        </v-row>

        <!-- Skeleton Loader -->
        <v-container v-if="loading">
          <v-row
            v-for="(skeleton, index) in skeletonCount"
            class="align-center mb-5"
            :key="index"
          >
            <SkeletonTemplate />
          </v-row>
        </v-container>

        <v-row>
          <v-pagination
            class="mx-auto my-5"
            v-model="allEventPage"
            :length="allEventTotalPages"
          ></v-pagination>
        </v-row>
      </div>
    </div>
  </Animated>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import {
  getAllEventsSuperAdmin,
  getAllBusiness,
} from '@/services/seller.service'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getMyData } from '@/services/user.service'
import EventSuperAdminFilteredTemplate from '@/components/Seller/EventSuperAdminFilteredTemplate.vue'
import SkeletonTemplate from '@/components/Seller/SkeletonTemplate.vue'
import { ErrorMessages } from '@/helpers/constants'

export default {
  data() {
    return {
      user: null,
      allEventsFiltered: [],
      allEventPage: 1,
      allEventTotalPages: 1,
      loading: false,
      filterByBusiness: null,
      filterByPayment: null,
      business: null,
      skeletonCount: [0, 1, 2],
      payment: [
        { id: 0, name: 'No Pago' },
        { id: 1, name: 'Pago' },
        { id: '', name: 'Todos' },
      ],
    }
  },
  watch: {
    allEventPage(nextPage, _currentPage) {
      this.getAllEvents(nextPage, this.filterByPayment, this.filterByBusiness)
    },
  },
  computed: {},
  methods: {
    async getMyData() {
      try {
        const { data } = await getMyData()
        this.user = data

        if (this.user.role_id !== 7) {
          Vue.$toast.error(`No tienes permiso para acceder a esta página`)
          setTimeout(() => {
            this.$router.push('/seller')
          }, 2000)
        } else {
          this.getAllEvents(
            this.allEventPage,
            this.filterByPayment,
            this.filterByBusiness
          )
          this.getAllBusiness()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getAllEvents(page, paid, business_id) {
      try {
        this.loading = true
        const { meta, data } = await getAllEventsSuperAdmin(
          page,
          5,
          paid,
          business_id
        )
        this.allEventTotalPages = Math.ceil(meta.total / 5)
        this.allEvents = data
        this.allEventsFiltered = this.allEvents
        this.loading = false
      } catch (error) {
        Vue.$toast.error(ErrorMessages.COMMON)
      }
    },
    async getAllBusiness() {
      const { data } = await getAllBusiness()
      const all = { id: '', name: 'Todos' }
      data.data.push(all)
      this.business = data.data
    },
  },
  mounted() {
    this.getMyData()
  },
  components: {
    SellerHeader,
    EventSuperAdminFilteredTemplate,
    SkeletonTemplate,
  },
}
</script>

<style lang="scss" scope></style>
